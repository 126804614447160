<template>
  <div class="flex flex-col justify-between mb-auto h-screen bg-slate-900">
    <HeaderElement />
    <router-view></router-view>
    <FooterElement />
  </div>

</template>

<script>
import HeaderElement from './components/HeaderElement.vue'
import FooterElement from './components/FooterElement.vue'

export default {
  name: 'App',
  components: {
    HeaderElement,
    FooterElement,
  }
}
</script>