<template>
  <main class="mb-auto bg-slate-900">
    <h1 class="mx-auto my-10 text-4xl text-white text-center">GET IN TOUCH</h1>
    <div class="md:grid md:grid-cols-2 md:gap-12 space-y-10 md:space-y-0 md:w-2/3 mx-auto mb-6">
      <div class="justify-self-center w-full p-6  border border-slate-700">
        <p class="text-white mb-8 text-center">Email list signup</p>
        <p v-if="listSuccess" class="text-green-500">{{listSuccess}}</p>
        <Form @submit="submitListForm" :validation-schema="listSchema" v-slot="{ isSubmitting }" :keep-values="false">
          <div class="mb-6">
            <label for="listEmail" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
            <Field name="listEmail" type="text" id="listEmail" class="bg-gray-50 border border-gray-300 text-gray-900
                      text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500
                      block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                      dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                      dark:focus:border-blue-500" required />
            <ErrorMessage name="listEmail" class="text-red-500" />
          </div>
          <div class="mb-6">
            <label for="confirmEmail" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Confirm email</label>
            <Field name="confirmEmail" type="text" id="confirmEmail" class="bg-gray-50 border border-gray-300 text-gray-900
                      text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500
                      block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                      dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                      dark:focus:border-blue-500" required rules="confirmed:@listEmail" />
            <ErrorMessage name="confirmEmail" class="text-red-500" />
          </div>
          <div class="mb-6">
            <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800
                      focus:ring-4 focus:outline-none focus:ring-blue-300
                      font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5
                      text-center dark:bg-blue-600 dark:hover:bg-blue-700
                      dark:focus:ring-blue-800 md:float-right relative" :class="{ [`submitting text-blue-600`]: isSubmitting}" :disabled="isSubmitting">Submit</button>
          </div>
        </Form>
      </div>
      <div class="justify-self-center w-full p-6 mb-6 border border-slate-700">
        <p class="text-white mb-8 text-center">Send us a note</p>
        <p v-if="contactSuccess" class="text-green-500">{{contactSuccess}}</p>
        <p v-if="contactError" class="text-red-500">{{contactError}}</p>
        <Form @submit="submitContactForm" :validation-schema="contactSchema" :keep-values="true" v-slot="{ isSubmitting }">
          <div class="mb-6">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your name</label>
            <Field name="name" type="text" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            <ErrorMessage name="name" class="text-red-500" />
          </div>
          <div class="mb-6">
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
            <Field name="email" type="text" id="email" class="bg-gray-50 border border-gray-300 text-gray-900
                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500
                                block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                dark:focus:border-blue-500" required />
            <ErrorMessage name="email" class="text-red-500" />
          </div>
          <div class="mb-6">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
            <Field as="textarea" name="message" id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50
                                rounded-lg border border-gray-300 focus:ring-blue-500
                                focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600
                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                dark:focus:border-blue-500" placeholder="Leave a comment..."></Field>
            <ErrorMessage name="message" class="text-red-500" />
          </div>
          <div class="mb-6">
            <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800
                              focus:ring-4 focus:outline-none focus:ring-blue-300
                              font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5
                              text-center dark:bg-blue-600 dark:hover:bg-blue-700
                              dark:focus:ring-blue-800 md:float-right relative"
            :class="{ [`submitting text-blue-600`]: isSubmitting}" :disabled="isSubmitting">Submit</button>
          </div>
        </Form>
      </div>
    </div>
  </main>
</template>

<script setup>
  import { ref} from 'vue'
  import { Form, Field, ErrorMessage } from 'vee-validate'
  import * as yup from 'yup'
  import axios from 'axios'

  const contactSuccess = ref('')
  const contactError = ref('')
  const listSuccess = ref('')

  const listSchema =  yup.object({
    listEmail: yup.string().required().email().label('Email'),
    confirmEmail: yup.string().required().email().oneOf([yup.ref('listEmail'), null], 'Emails must match').label('Email confirmation'),
  })

  const contactSchema = yup.object({
    name: yup.string().required().label('Name'),
    email: yup.string().required().email().label('Email'),
    message: yup.string().required().min(10).label('Message'),
  })

  function submitContactForm(values, actions) {
    values.token = process.env.VUE_APP_FORM_KEY
    axios.post('https://www.southstreetsix.com/contactHandler.php', values, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
    .then((response) =>  {
      console.log(response)
      actions.resetForm()
      contactSuccess.value = 'Thanks for contacting us. We\'ll be in touch soon!'
    })
    .catch((response) => {
      actions.resetForm()
      contactError.value = response.response.data.message
    })
  }

  function submitListForm(values, actions) {
    const login = () => {
      const obj = {
        email: values.listEmail,
        'list[2]': 'signup',
        'listname[2]': 'Events',
      }
      axios.post('https://www.southstreetsix.com/lists/?p=asubscribe&id=1', obj, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((response) => {
        console.log(response)
        actions.resetForm()
        listSuccess.value = 'Thanks for signing up!'
      })
      .catch(error => {
        if (error.response.status == 409) {
          listSuccess.value = "You're already on the list, thanks!"
        }
      })
    }
    login()
  }

</script>

<style scoped>
button.submitting::after {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: calc(50% - 0.7rem);
    left: 2rem;
    border-radius: 2em;
    border-color: transparent transparent white white;
    border-style: solid;
    border-width: 0.15em;
    animation: spinner-rotation 0.75s infinite;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  @keyframes spinner-rotation {
    to {
      transform: rotate(360deg);
    }
  }
</style>