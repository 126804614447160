<template>
  <main class="bg-slate-900">
    <h1 class="mx-auto my-10 text-4xl text-white text-center">PHOTOS</h1>
    <div class="container mx-auto">
      <div class="lg:grid lg:grid-cols-4 lg:gap-4 space-y-2 justify-items-center justify-content-center">
        <div v-for="item in items" :key="item.id" class="w-full rounded">
          <a href="" @click.prevent="modal=true, modalsrc = item.src, imgid = item.id, max=max"><img class="rounded-lg block object-cover object-center h-full w-full" :src="item.src" /></a>
        </div>
      </div>
    </div>
    <ModalElement :modal="modal" :modalsrc="modalsrc" :id="imgid" :max="max" @close-modal="closeModal" @next-pic="nextPic" @prev-pic="prevPic" />
  </main>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import ModalElement from '../components/ModalElement.vue'

  const modal = ref(false)
  const modalsrc = ref('')
  const imgid = ref('')
  const max = ref('')
  const items = ref([
    {id: 1, src: "/images/photos/bos25-tall.jpg"},
    {id: 2, src: "/images/photos/cbs-scene.jpg"},
    {id: 3, src: "/images/photos/chieftain-1.jpg"},
    {id: 4, src: "/images/photos/chris-singing.jpg"},
    {id: 5, src: "/images/photos/crowd-shot.jpg"},
    {id: 6, src: "/images/photos/dressed-up.jpg"},
    {id: 7, src: "/images/photos/jt-singing.jpg"},
    {id: 8, src: "/images/photos/middle-east.jpg"},
    {id: 9, src: "/images/photos/pat-patriot.jpg"},
    {id: 10, src: "/images/photos/renee-singing.jpg"},
    {id: 11, src: "/images/photos/middle-east-2.jpg"},
    {id: 12, src: "/images/photos/ryan-closeup.jpg"},
    {id: 13, src: '/images/photos/piper.jpg'},
  ])

  onMounted(() => {
    max.value = items.value.length
    console.log(max.value)
  })

  function closeModal() {
    modal.value = false
  }

  function nextPic(id) {
    var res = items.value.find(item => item.id == id+1);
    if (typeof res == "undefined") {
      return false
    }
    modalsrc.value = res.src
    imgid.value = id + 1
  }

  function prevPic(id) {
    var res = items.value.find(item => item.id == id - 1);
    if (typeof res == "undefined") {
      return false
    }
    modalsrc.value = res.src
    imgid.value = id - 1
  }
</script>