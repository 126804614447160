<template>
  <header class="md:h-14 px-6 md:px-16 py-4 bold bg-black">
    <nav>
      <div class="md:hidden">
        <button class="rounded-lg w-5 text-white" @click.prevent="toggleNav">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            viewBox="0 0 24 24" class="w-8 h-8">
            <path d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
        <aside class="transform top-0 left-0 w-full bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30"
          :class="navState ? 'translate-x-0' : '-translate-x-full'">
          <div class="w-full text-right pr-4 pt-2" @click="toggleNav">X</div>
          <router-link to="/" @click="toggleNav" class="block h-12 pt-2 text-center text-xl">HOME</router-link>
          <router-link to="/gigs" @click="toggleNav" class="block h-12 pt-2 text-center text-xl">GIGS</router-link>
          <router-link to="/photos" @click="toggleNav" class="block h-12 pt-2 text-center text-xl">PHOTOS</router-link>
          <router-link to="/video" @click="toggleNav" class="block h-12 pt-2 text-center text-xl">VIDEO</router-link>
          <a href="https://south-street-six.creator-spring.com/" class="block h-12 pt-2 text-center text-xl">SSSWAG</a>
          <router-link to="/contact" @click="toggleNav" class="block h-12 pt-2 text-center text-xl">CONTACT</router-link>
        </aside>
      </div>
      <ul class="hidden md:flex space-x-8 md:visible">
        <li class="relative group">
            <router-link to="/" class="block text-white">HOME</router-link>
            <span class="absolute -bottom-0 left-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
            <span class="absolute -bottom-0 right-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
        </li>
        <li class="relative group">
          <router-link to="/gigs" class="block text-white">GIGS</router-link>
          <span class="absolute -bottom-0 left-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
          <span class="absolute -bottom-0 right-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
        </li>
        <li class="relative group">
          <router-link to="/photos" class="block text-white">PHOTOS</router-link>
          <span class="absolute -bottom-0 left-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
          <span class="absolute -bottom-0 right-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
        </li>
        <li class="relative group">
          <router-link to="/video" class="block text-white">VIDEO</router-link>
          <span class="absolute -bottom-0 left-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
          <span class="absolute -bottom-0 right-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
        </li>
        <li class="relative group">
          <a href="https://south-street-six.creator-spring.com/" class="block text-white">SSSWAG</a>
          <span class="absolute -bottom-0 left-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
          <span class="absolute -bottom-0 right-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
        </li>
        <li class="relative group">
          <router-link to="/contact" class="block text-white">CONTACT</router-link>
          <span class="absolute -bottom-0 left-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
          <span class="absolute -bottom-0 right-1/2 w-0 h-px bg-gray-400 duration-300 group-hover:w-1/2 group-hover:transition-all"></span>
        </li>
      </ul>
    </nav>
    <img src="@/assets/images/SSSLogoWhite.png" class="float-right absolute top-2 right-2 md:top-0 md:right-0 w-36 md:w-64" />
  </header>
</template>

<script setup>
  import { ref } from 'vue'

  const navState = ref(false)

  function toggleNav() {
    navState.value = !navState.value
    console.log(navState.value)
  }
</script>

<style scoped>
@media (max-width: 767px) {
  .router-link-active {
    background-color: rgb(156, 163, 175);
  }
}
@media (min-width: 768px) {
  .router-link-active {
    border-bottom: 1px solid #ccc;
  }
}
</style>