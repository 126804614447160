<template>
  <main class="h-full home-background"></main>
</template>

<style scoped>
.home-background {
    background: url('~@/assets/images/sss-background-2.jpg') no-repeat 10% fixed;
      background-size: cover;
      height: 100%;
      width: auto;
  }
</style>