import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import GigsPage from "../views/GigsPage.vue";
import VideosPage from "../views/VideosPage.vue";
import PhotosPage from "../views/PhotosPage.vue";
import ContactPage from "../views/ContactPage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePage,
    },
    {
      path: "/gigs",
      name: "gigs",
      component: GigsPage,
    },
    {
      path: "/photos",
      name: "photos",
      component: PhotosPage,
    },
    {
      path: "/contact",
      name: "contact",
      component: ContactPage,
    },
    {
      path: "/video",
      name: "video",
      component: VideosPage,
    },
  ],
});

export default router;