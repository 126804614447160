<template>
  <main class="bg-slate-900">
    <h1 class="mx-auto my-10 text-4xl text-white text-center">VIDEOS</h1>
    <svg v-if="loading" class="animate-spin h-10 w-10 mx-auto absolute top-1/2 left-[49%]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="rgb(37 99 235 / var(--tw-bg-opacity))" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    <div class="md:grid md:grid-cols-2 md:gap-6 md:w-3/4 3xl:w-1/2 mx-auto justify-items-center">
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/1OQtVa5LZgc" title="YouTube video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/sglPQNcfaGE" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/MWwpYeavyIw" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/lAtjYRPRd4U" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/NjVNFZUn5Ug" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/QLFAH_QKbeI" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/VYo4EWWVjkk" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/9Av8qV6XNbo" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/QfkWkfzFs3M" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/GU05lQxYsGY" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/JN42tA8qTR8" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <div class="mt-4 md:mt-0">
        <iframe class="md:w-[560px] md:h-[315px] w-full" src="https://www.youtube.com/embed/RkwAy3jdn9s" title="YouTube video player"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <p></p>
    </div>
  </main>
</template>

<script setup>
  import { ref } from 'vue'
  const loading = ref(true)
  setTimeout(function() {
      loading.value = false
    }, 2000)
</script>