<template>
  <footer class="h-10 bg-blue-600">
    <ul class="flex justify-center items-center py-4 h-10">
      <li class="mx-6 text-white hover:text-gray-400">
        <!--Facebook -->
        <a href="https://www.facebook.com/southstreetsix" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 18" class="fill-current w-6 h-6">
            <path
              d="M1.907 18V8.999H0V5.897h1.907V4.035C1.907 1.505 2.984 0 6.044 0h2.547v3.103H7c-1.191 0-1.27.433-1.27 1.242l-.005 1.552h2.885L8.27 9H5.724V18H1.907z">
            </path>
          </svg>
        </a>
      </li>
      <!-- YouTube -->
      <li class="mx-6 text-white hover:text-gray-400">
        <a href="https://www.youtube.com/@southstreetsix" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14" class="w-6 h-6 fill-current">
            <path
              d="M7.518.011l1.81-.01c.971.004 3.89.03 5.972.173l.355.05c.394.061.95.209 1.445.76.463.478.66 1.426.714 1.746l.017.108s.155 1.378.168 2.816L18 7.241c0 .654-.032 1.31-.068 1.84l-.1 1.172s-.17 1.274-.732 1.853c-.675.753-1.463.753-1.8.811l-.537.033c-2.11.12-4.778.138-5.547.14l-2.132-.03c-1.465-.029-3.372-.076-4.215-.143-.394-.116-1.294-.058-1.969-.81-.43-.444-.63-1.293-.7-1.668l-.031-.186S.014 8.875 0 7.437V5.654l.007-.39c.012-.45.035-.883.06-1.254l.1-1.172S.339 1.564.9.985C1.575.232 2.362.232 2.7.174l.75-.045C4.827.057 6.4.025 7.518.01zm-.973 3.262v6.545l6.546-3.273-6.546-3.272z">
            </path>
          </svg>
        </a>
      </li>
      <!-- Instagram -->
      <li class="mx-6 text-white hover:text-gray-400">
        <a href="https://www.instagram.com/southstreetsix" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="10 10 45 45" class="w-6 h-6 fill-current">
            <path
              d="M 31.820312 12 C 13.439312 12 12 13.439312 12 31.820312 L 12 32.179688 C 12 50.560688 13.439312 52 31.820312 52 L 32.179688 52 C 50.560688 52 52 50.560688 52 32.179688 L 52 32 C 52 13.452 50.548 12 32 12 L 31.820312 12 z M 28 16 L 36 16 C 47.129 16 48 16.871 48 28 L 48 36 C 48 47.129 47.129 48 36 48 L 28 48 C 16.871 48 16 47.129 16 36 L 16 28 C 16 16.871 16.871 16 28 16 z M 41.994141 20 C 40.889141 20.003 39.997 20.900859 40 22.005859 C 40.003 23.110859 40.900859 24.003 42.005859 24 C 43.110859 23.997 44.003 23.099141 44 21.994141 C 43.997 20.889141 43.099141 19.997 41.994141 20 z M 31.976562 22 C 26.454563 22.013 21.987 26.501437 22 32.023438 C 22.013 37.545437 26.501437 42.013 32.023438 42 C 37.545437 41.987 42.013 37.498562 42 31.976562 C 41.987 26.454563 37.498562 21.987 31.976562 22 z M 31.986328 26 C 35.299328 25.992 37.992 28.673328 38 31.986328 C 38.007 35.299328 35.326672 37.992 32.013672 38 C 28.700672 38.008 26.008 35.327672 26 32.013672 C 25.992 28.700672 28.673328 26.008 31.986328 26 z">
            </path>
          </svg>
        </a>
      </li>
      <li class="absolute right-0 mr-2 text-white text-sm">
        <span class="inline-block"><img src="@/assets/images/copy-icon.png" class="h-4 w-4 inline-block mb-0.5 mr" /> 2022</span>
      </li>
    </ul>
  </footer>
</template>

<script>

export default {
  name: 'FooterElement',
}
</script>