<template>
  <main class="mb-auto h-full bg-slate-900">
    <h1 class="mx-auto my-10 text-4xl text-white text-center">UPCOMING SHOWS</h1>
    <div class="bg-slate-800/25 rounded-xl md:w-1/2 mx-auto">
      <div class="bg-grid-slate-100">
        <div class="shadow-sm overflow-hidden my-8 rounded-xl">
          <table class="table-auto border-collapse text-sm w-full">
            <thead>
              <tr>
                <th class="border-b border-slate-600 font-bold p-4 pl-8 pt-0 pb-3 text-gray-300 text-left">Date</th>
                <th class="border-b border-slate-600 font-bold p-4 pl-8 pt-0 pb-3 text-gray-300 text-left">Venue</th>
                <th class="border-b border-slate-600 font-bold p-4 pl-8 pt-0 pb-3 text-gray-300 text-left">More Info</th>
              </tr>
            </thead>
            <tbody class="bg-slate-800">
              <tr>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">Aug 2, 2024</td>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">SSS at The Gavel, 7-10pm</td>
		<td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8"><a href="https://www.facebook.com/events/1993313057761779/">View on Facebook</a></td>
              </tr>
              <tr>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">Sept 27, 2024</td>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">SSS at Patriot Place, 5-8pm</td>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">More info soon!</td>
              </tr>
              <tr>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">Nov 15, 2024</td>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">SSS at The Chieftain, 8-11pm</td>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">More info soon!</td>
              </tr>
              <tr>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">Dec 6, 2024</td>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">SSS at CraftRoots, 6-9pm</td>
                <td class="text-gray-300 text-left border-b border-slate-700 p-4 pl-8">More info soon!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="mx-auto p-4 w-full text-center text-white">Check back soon for more shows!</div>
  </main>
</template>
