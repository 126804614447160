<template>
  <div v-if="modal" id="popup-modal" tabindex="-1"
    class="flex bg-slate-900/90 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full justify-center items-center"
    aria-hidden="true" @click.self="$emit('closeModal')">
    <div class="relative md:p-4 w-full lg:w-3/4 h-auto opacity-1">
      <div class="relative my-auto rounded-lg shadow bg-black/50">
        <img class="mx-auto" :src="modalsrc" :id="id" />
        <a @click.prevent="$emit('prevPic', props.id)" href="" v-if="props.id > 1">
          <div class="absolute left-2 top-1/2 text-8xl text-red-700 drop-shadow-md">
            <svg class="h-16 w-10 drop-shadow-sm stroke-slate-300/80 stroke-1 md:stroke-2 fill-none hover:stroke-white" xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24"
              stroke-linecap="round" stroke-linejoin="round">
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </div>
        </a>
        <a @click.prevent="$emit('nextPic', props.id)" href="" v-if="props.id < max">
          <div class="absolute right-2 top-1/2 text-8xl text-red-700 drop-shadow-md">
            <svg class="h-16 w-10 drop-shadow-sm stroke-slate-300/80 stroke-1 md:stroke-2 fill-none hover:stroke-white" xmlns="http://www.w3.org/2000/svg" viewBox="6 0 12 24"
              stroke-linecap="round" stroke-linejoin="round">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
        </a>
        <button class="absolute top-0 right-0 bg-slate-400/60 hover:bg-white/60 px-2 py-1 text-xl md:text-4xl" @click="$emit('closeModal')">X</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue'
  const props = defineProps(['modal', 'modalsrc', 'id', 'max'])
  const emit = defineEmits(['closeModal', 'nextPic', 'prevPic'])
  onMounted(() => {
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        emit('closeModal')
      }
      if (e.key === 'ArrowRight') {
        emit('nextPic', props.id)
      }
      if (e.key === 'ArrowLeft') {
        emit('prevPic', props.id)
      }
    })
  })
</script>